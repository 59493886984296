import React from 'react';
import Layout from '../layouts/index';
import BookImage from '../images/book-eBookBundle.png';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { ReactPhotoCollage } from 'react-photo-collage';
import Recommended from '../components/Recommended';
import Link from 'gatsby-link';
import Payhip from '../components/Payhip';

class CourseDetails extends React.Component {
	render() {
		const setting = {
			width: '100%',
			height: ['500px', '170px'],
			layout: [1, 4],
			photos: [
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page1.png',
				},
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page2.png',
				},
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page3.png',
				},
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page4.png',
				},
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page5.png',
				},
			],
			showNumOfRemainingPhotos: true,
		};
		return (
			<Layout>
				{/* Course info */}
				<div className="container-fluid margin-from-header bg-white">
					<div className="container pt-4 pt-md-8 pb-8">
						<div className="row d-flex justify-content-md-center reverse-flex-mobile">
							<div className="col-sm-12 col-md-5 d-flex flex-column justify-content-left mr-md-6">
								<h2>Vietnamese eBook Bundle</h2>
								<p>
									Get all your favorite Vietnamese eBooks in one single bundle
									to boost your learning.
								</p>
								<p>
									Level: <b>Absolute Beginner - Intermediate</b>
								</p>
								{/*<h1 className="text-success">$9.99</h1>*/}
								<div className="row pl-1 mt-2">
									<Payhip productID="mOfny" label="Buy now - $28.99"></Payhip>
									{/* <OutboundLink
                    class="btn-simple btn-primary-outline box-shadow "
                    // href="https://gum.co/lspsz?wanted=true"
                    href="https://payhip.com/b/WmyO8"
                  >
                    Get now for $29
                  </OutboundLink> */}
								</div>
							</div>
							<div className="col-7 col-md-4 mb-2 align-self-center">
								<img
									src={BookImage}
									className="img-fluid float-right rounded"
									alt="Vietnamese eBook Bundle"
								></img>
							</div>
						</div>
					</div>
				</div>

				{/* Course contents */}
				<div className="container pt-md-10 pb-md-10 pt-6 pb-6">
					<div className="row justify-content-md-center">
						<div className="col-sm-12 col-md-8 ml-1 mr-1">
							{/* <h2>What will you achieve?</h2>
              <ul>
                <li>Learn 1000 essential Vietnamese words, including nouns, verbs, adjective, etc.</li>
                <li>All words are listed in alphabetical order, including Northern and Southern words.</li>
                <li>BONUS: Learn basic Vietnamese pronunciation: Vowels, Consonants and Tones.</li>
              </ul> */}
							<h2 className="">What's included?</h2>
							<p>
								<b>❶ Basic Grammar & Sentence Patterns (Book 1)</b> —{' '}
								<Link
									className="text-decoration-underline"
									to="/vietnamese-sentence-patterns-book-1"
									target="_blank"
								>
									View Book
								</Link>
							</p>
							<ul>
								<li>PDF eBook version - 1 file</li>
								<li>EPUB eBook version - 1 file</li>
								<li>Audio MP3s - 1 zip file</li>
							</ul>
							<p>
								<b>❷ Basic Grammar & Sentence Patterns (Book 2)</b> —{' '}
								<Link
									className="text-decoration-underline"
									to="/vietnamese-sentence-patterns-book-2"
									target="_blank"
								>
									View Book
								</Link>
							</p>
							<ul>
								<li>PDF eBook version - 1 file</li>
								<li>EPUB eBook version - 1 file</li>
								<li>Audio MP3s - 1 zip file</li>
							</ul>
							<p>
								<b>❸ Vocabulary Builder by Theme</b> —{' '}
								<Link
									className="text-decoration-underline"
									to="/vietnamese-vocabulary-builder-book-1"
									target="_blank"
								>
									View Book
								</Link>
							</p>
							<ul>
								<li>PDF eBook version - 1 file</li>
								<li>EPUB eBook version - 1 file</li>
							</ul>
						</div>
					</div>
				</div>
				<Recommended
					recommended={[
						'/vietnamese-sentence-patterns-book-1',
						'/vietnamese-sentence-patterns-book-2',
						'/vietnamese-vocabulary-builder-book-1',
					]}
				/>
			</Layout>
		);
	}
}

export default CourseDetails;
